<template>
  <div
    class="fill-width flex-grow-1 d-flex flex-column"
  >
    <div
      v-if="section"
    >
      <v-row>
        <v-col>
          <v-icon class="mr-1">
            mdi-speedometer
          </v-icon>
          {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.section.speed') }}
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.section.date') }} :</th>
                  <th class="text-center">
                    {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.section.now') }}
                  </th>
                  <th
                    v-if="date !== null"
                    class="text-center"
                  >
                    {{ moment(date).format('hh') + 'h' + moment(date).format('mm') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    {{ $t('labels.section.jamFactor') }} :
                  </th>
                  <td class="primary--text text-no-wrap text-center">
                    <span v-if="jamFactor !== null && jamFactor !== -1">
                      {{ jamFactor * 10 }} %
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                  <td
                    v-if="date !== null"
                    class="primary--text text-no-wrap text-center"
                  >
                    <o-loader v-if="history === null" />
                    <span v-else-if="history.jamFactor !== null && history.jamFactory !== -1">
                      {{ history.jamFactor * 10 }} %
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('labels.section.freeFlowSpeed') }} :
                  </th>
                  <td class="primary--text text-no-wrap text-center">
                    <span v-if="freeFlowSpeed !== null">
                      {{ freeFlowSpeed }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                  <td
                    v-if="date !== null"
                    class="primary--text text-no-wrap text-center"
                  >
                    <o-loader v-if="history === null" />
                    <span v-else-if="history.freeFlowSpeed !== null">
                      {{ history.freeFlowSpeed }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('labels.section.averageSpeed') }} :
                  </th>
                  <td class="primary--text text-no-wrap text-center">
                    <span v-if="averageSpeed !== null">
                      {{ averageSpeed }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                  <td
                    v-if="date !== null"
                    class="primary--text text-no-wrap text-center"
                  >
                    <o-loader v-if="history === null" />
                    <span v-else-if="history.averageSpeed !== null">
                      {{ history.averageSpeed }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('labels.section.averageSpeedUncut') }} :
                  </th>
                  <td class="primary--text text-no-wrap text-center">
                    <span v-if="averageSpeedUncut !== null">
                      {{ averageSpeedUncut }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                  <td
                    v-if="date !== null"
                    class="primary--text text-no-wrap text-center"
                  >
                    <o-loader v-if="history === null" />
                    <span v-else-if="history.averageSpeedUncut !== null">
                      {{ history.averageSpeedUncut }} km/h
                    </span>
                    <span
                      v-else
                      class="font-italic"
                    >
                      {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.unknown') }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon class="mr-1">
            mdi-information
          </v-icon>
          {{ $t('messages.views.admin.components.dashboard.sectionModal.window.information.section.information') }}
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>
                    {{ $t('labels.section.traversability') }} :
                  </th>
                  <td class="primary--text text-no-wrap">
                    {{ $t('constants.section.traversability.' + section.traversability) }}
                  </td>
                  <td />
                </tr>
                <tr>
                  <th>
                    {{ $t('labels.section.length') }} :
                  </th>
                  <td class="primary--text text-no-wrap">
                    {{ formatNumber(section.length.toFixed(0)) }} m
                  </td>
                  <td />
                </tr>
                <tr v-if="section.tmcCode">
                  <th>
                    {{ $t('labels.section.tmcCode') }} :
                  </th>
                  <td class="primary--text text-no-wrap">
                    {{ section.tmcCode }}
                  </td>
                  <td>
                    <o-copy
                      :content="section.tmcCode"
                    />
                  </td>
                </tr>
                <tr v-if="section.olr">
                  <th>
                    {{ $t('labels.section.olr') }} :
                  </th>
                  <td
                    class="primary--text"
                  >
                    <div
                      class="text-truncate"
                      style="width: 230px;"
                    >
                      {{ section.olr }}
                    </div>
                  </td>
                  <td>
                    <o-copy
                      :content="section.olr"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('labels.section.updatedAt') }} :
                  </th>
                  <td class="primary--text text-no-wrap">
                    {{ section.updatedAt|localMoment('L') }} {{ section.updatedAt|localMoment('LTS') }}
                  </td>
                  <td />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <o-loader
      v-else
      class="fill-height flex-grow-1"
    />
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { formatNumber } from '@/utils/number';

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      date: {
        type: Date,
        default: null,
      },
      override: {
        type: Object,
        default: null,
      },
    },

    apollo: {
      section: {
        query: require('@gql/views/admin/components/dashboard/section-modal/window/information/getSection.gql'),
        client: 'floating-traffic-data',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            id: parseInt(this.value),
          };
        },
        update: function (data) {
          return data.getSection;
        },
      },
    },

    data: () => ({
      history: null,
    }),

    computed: {
      averageSpeed () {
        if (this.override && this.override.averageSpeed !== undefined) {
          return parseFloat(this.override.averageSpeed).toFixed(1);
        } else {
          return this.section.averageSpeed !== null
            ? this.section.averageSpeed.toFixed(1)
            : null
          ;
        }
      },
      averageSpeedUncut () {
        if (this.override && this.override.averageSpeedUncut !== undefined) {
          return parseFloat(this.override.averageSpeedUncut).toFixed(1);
        } else {
          return this.section.averageSpeedUncut !== null
            ? this.section.averageSpeedUncut.toFixed(1)
            : null
          ;
        }
      },
      freeFlowSpeed () {
        if (this.override && this.override.freeFlowSpeed !== undefined) {
          return parseFloat(this.override.freeFlowSpeed).toFixed(1);
        } else {
          return this.section.freeFlowSpeed !== null
            ? this.section.freeFlowSpeed.toFixed(1)
            : null
          ;
        }
      },
      jamFactor () {
        if (this.override && this.override.jamFactor !== undefined) {
          return parseFloat(this.override.jamFactor).toFixed(1);
        } else {
          return this.section.jamFactor !== null
            ? this.section.jamFactor.toFixed(1)
            : null
          ;
        }
      },
    },

    watch: {
      value: function (newValue, oldValue) {
        this.section = null;
      },
    },

    mounted () {
      if (this.date) {
        this.$apollo.query({
          query: require('@gql/views/admin/components/dashboard/section-modal/window/information/history.gql'),
          client: 'stats',
          variables: {
            at: moment(this.date).format('Y-MM-DD HH:mm:ss'),
            step: 30,
            entityId: parseInt(this.value),
            timezone: moment.tz.guess(),
          },
        })
          .then((result) => {
            this.history = {
              jamFactor: result.data.jamFactor.toFixed(1),
              freeFlowSpeed: result.data.freeFlowSpeed.toFixed(1),
              averageSpeed: result.data.averageSpeed.toFixed(1),
              averageSpeedUncut: result.data.averageSpeedUncut.toFixed(1),
            };
          }).catch(() => {
            this.$flash(null, 'error');
          })
        ;
      }
    },

    methods: {
      formatNumber (number) {
        return formatNumber(number);
      },
    },
  };
</script>
